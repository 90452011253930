.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: #0bb10b00;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover {  
    background-color: #20ad2033;
    animation-name: unset;
    opacity: 0.99;
}

.tg-icon svg,
.whatsapp-icon svg {
  width: 40px;
  height: 40px;
}

.tg-icon svg {
  width: 45px;
  height: 45px;
  fill: #fff;
  border-radius: 5px;
}

.tg-icon {
    bottom: 160px;
    background: #03a9f400;
}

.tg-icon:hover {
    background: #03a9f433;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(-10deg)scale(1.1);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}